<div  class="dashboard flex flex-col">
  <!--app-license-assign></app-license-assign-->
  <mat-card class="dashboard__licenses card card--large card--soft-shadow">
    <mat-card-title><mat-icon color="primary">smart_display</mat-icon><span i18n>Aktuální licence</span></mat-card-title>
    <div  class="dashboard__licenses__container">
      <app-licenses-list [hideExpired]="true" [template]="'accordion'" [assignable]="false" ></app-licenses-list>
    </div>
  </mat-card>
  <mat-card class="dashboard__plans card card--large card--soft-shadow ">
      <mat-card-title><mat-icon color="primary">dashboard</mat-icon><span i18n>Naše služby</span></mat-card-title>
      <app-plans-list [template]="planTemplateEnum.COMPACT"></app-plans-list>
  </mat-card>
</div>