<div class="order-extend" *ngIf="!noLicenses; else noLicensesTemplate">
  <div class="order-extend__left">
    <app-licenses-list [selected]="selectedLicensesId" [filters]="licenseFilters" [selectable]="true" [extendable]="true" [template]="'table'" (isEmpty)="isEmptyList($event)" (selectionUpdate)="onLicenseSelection($event)"></app-licenses-list>
  </div>
  <div class="order-extend__right">
    <app-pictogram  [title]="pictogramTitle" [text]="pictogramText" [width]="130" icon="license-settings"></app-pictogram>
    <form [formGroup]="extendFormGroup" class="branch-new flex flex-wrap" >
      <mat-form-field class="u__flex--fullrow">
        <mat-label i18n>Délka licence</mat-label>
        <mat-select formControlName="subscriptionLength" >
          <mat-option value="1" i18n>1 měsíc</mat-option>
          <mat-option value="3" i18n>3 měsíce</mat-option>
          <mat-option value="6" i18n>6 měsíců</mat-option>
          <mat-option value="12" i18n>1 rok (sleva 10%)</mat-option>
        </mat-select>
      </mat-form-field>
      <div  class="flex flex-col" >
      <mat-slide-toggle *ngIf="orderItems.length > 1" color="primary" class="mb-4" formControlName="subscriptionAlign" i18n>Zarovnat všechny ke stejném datumu</mat-slide-toggle>
      <div class="order-extend__align-date" *ngIf="orderItems.length > 0 ">
        <span i18n>Konec licence bude k </span>{{ orderItems[0].newLicense?.endDate | date }}
      </div>
      <!--mat-slide-toggle matTooltip="Aktivuje automatické prodlužování a placení služby" i18n-matTooltip color="primary" formControlName="autoRenew" i18n>Automaticky obnovit licenci</mat-slide-toggle-->
    </div>
    </form>
  </div>
</div>

<ng-template #noLicensesTemplate>
    <div i18n>Nemáte žádnou licenci, kterou lze prodloužit.</div>
</ng-template>
