<div class="orders-history" *ngIf="!noCompany ">
  <div *ngIf="!loadingResults; else loading">
  <mat-accordion  multi>
    <mat-expansion-panel  class="orders-history__expansion-panel" *ngFor="let order of orders">
      <mat-expansion-panel-header class="orders-history__expansion-header" >
        <mat-panel-title>
          {{ order.index }} - &nbsp;
          <span class="orders-history__status" *ngIf="order.orderState == orderState.PAID" i18n>Uhrazeno</span>
          <span class="orders-history__status"*ngIf="order.orderState == orderState.PAYMENT_METHOD_CHOSEN" i18n>Čeká na zaplacení</span>
          <span class="orders-history__status" *ngIf="order.orderState == orderState.TIMEOUTED || order.orderState == orderState.CANCELED" i18n>Nezaplacená (zrušená) </span>
          <span class="orders-history__status" *ngIf="order.orderState == orderState.CREATED" i18n>Čekající na zaplacení</span>
          <span class="orders-history__status" *ngIf="order.orderState == orderStateEnum.REFUNDED || order.orderState == orderStateEnum.PARTIALLY_REFUNDED" i18n>Vrácení platby</span>


        </mat-panel-title>
        <mat-panel-description class="orders-history__description flex justify-between">
          <div>
            <span *ngIf="order.stateHistory" i18n><span class="orders-history__order-date">Datum objednávky</span><span> {{ convertDate(order.stateHistory[0].time.$date.$numberLong) }}</span></span>
          </div>
          <div class="flex items-center gap-5">
            <button *ngIf="order.id && (order.orderState == orderStateEnum.CREATED || order.orderState == orderStateEnum.PAYMENT_METHOD_CHOSEN)"  class="mat-mdc-raised-button--shadow mat-mdc-raised-button--small" mat-raised-button color="primary" (click)="repeatPayment(order)" i18n>Uhradit</button>
            <button *ngIf="order.id && (order.orderState == orderStateEnum.CANCELED)"  class="mat-mdc-raised-button--shadow mat-mdc-raised-button--small" mat-raised-button color="primary" (click)="repeatPayment(order)" i18n>Opakovat platbu</button>
            <button *ngIf="order.id && (order.orderState == orderStateEnum.TIMEOUTED)"  class="mat-mdc-raised-button--shadow mat-mdc-raised-button--small" mat-raised-button color="primary" (click)="repeatPayment(order)" i18n>Opakovat platbu</button>

            <button *ngIf="order.id && order.orderState == orderStateEnum.PAID" matTooltip="Stahnout fakturu v PDF" i18n-matTooltip mat-icon-button i18n-aria-label aria-label="Stáhnout fakturu v PDF"  (click)="downloadInvoice(order.id, order.index)"> <mat-icon color="primary">picture_as_pdf</mat-icon></button>
            <!--button class="mat-mdc-raised-button--shadow" *ngIf="order.id && order.orderState == orderStateEnum.CREATED && order.payment" mat-raised-button color="primary" (click)="payOrder(order)" i18n>Zaplatit</button-->
            <p class="orders-history__price" i18n>částka: {{ order.totalPrice * getVatMultiplayer(order.vatRate) | currency: order.currency  }}  </p>
           
          </div>
          
        </mat-panel-description>
      </mat-expansion-panel-header>
        <div class="orders-history__orders">
          
          <div class="orders-history__orders__header">
            <div  class="orders-history__orders__header-info">
              <p i18n>Plán</p>
              <p i18n>Název</p>
              <p i18n>Plocha prodejny</p>
            </div>
            <div style="text-align: right;"  class="orders-history__orders__header-price"> 
              <p style="text-align: right" i18n>Cena</p>
            </div>
          </div>

          <div *ngFor="let orderItem of order.orderItems" class="orders-history__orders__item" [class]="'orders-history__orders--' + orderItem.orderType" >
            
            <div :style="max-width: 620px" class="orders-history__orders__body justify-end" *ngIf="orderItem.orderType == orderTypeEnum.DISCOUNT">
              <p style="width:50%"><span i18n>Sleva po aktivaci kódu: </span> {{ orderItem.coupon?._id }} </p>
              <p  class="align-right" *ngIf="orderItem.coupon?.type=='PERCENT'">
                <span> -{{ orderItem.coupon?.value }}</span><span>%</span>
              </p>
              <p *ngIf="orderItem.coupon?.type=='FIXED'">
                <span> -{{ orderItem.coupon?.value[order.currency] | currency:order.currency }}</span>
              </p>
            </div>

            <div  class="orders-history__orders__body" *ngIf="orderItem.orderType !== orderTypeEnum.DISCOUNT">
              <div   class="orders-history__orders__info"  >
                <p > {{ orderItem.license.plan?.title }} </p>
                <p > {{ orderItem.license.player?.name }} </p>
                <p ><span i18n>plocha </span>{{ orderItem.license.player?.area }} m2</p>
              </div>
              <div style=" text-align: right;"  class="orders-history__orders__price flex flex-col"> 
                <span class="orders-history__orders__price-novat"> {{ orderItem.price | currency: order.currency }} <span i18n>bez DPH</span></span>
                <span>{{ order.totalPrice * getVatMultiplayer(order.vatRate) | currency: order.currency }} <span  i18n>s DPH</span></span>
              </div>
            </div>

          </div>
        <div class="orders-history__orders__actions ">
          <button class="mat-mdc-raised-button--shadow" *ngIf="order.id && order.orderState == orderStateEnum.PAID" mat-raised-button color="primary" (click)="downloadInvoice(order.id, order.index)" i18n>Stáhnout fakturu v PDF</button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="orders-no-order"  *ngIf="orders.length==0">
    <app-pictogram [width]="180" (onClick)="openBenefitsDetail()" buttonText="Projít výhody" template="horizontal" [title]="pictogramNoOrderTitle" [text]="pictogramNoOrderText" icon="no-orders"></app-pictogram>
  </div>

  
</div>
</div>
<div class="orders-history" *ngIf="noCompany">
  <app-pictogram [width]="180" (onClick)="openCompanyRegistration()" buttonText="Nastavit" template="horizontal" [title]="pictogramNoCompanyTitle" [text]="pictogramNoCompanyText" icon="no-company"></app-pictogram>
</div>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>

