import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Company } from 'src/app/models/company.model';
import { CompanyService } from '../company.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomUtils } from 'src/app/utils/custom-utils.component';

@Component({
  selector: 'app-company-registration',
  templateUrl: './company-registration.component.html',
  styleUrls: ['./company-registration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompanyRegistrationComponent implements OnChanges {
  @Input() company?: Company | null;
  companyFormGroup: FormGroup;
  isDisabled: boolean = true;
  isNew: boolean = false;
  @Output() companyUpdate = new EventEmitter<Company>();
  @Output() onValidation = new EventEmitter<any>();

  constructor(private fb: FormBuilder, private companyService: CompanyService,  @Inject(LOCALE_ID) public locale: string) {
    this.companyFormGroup = this.fb.group({
      regnum: new FormControl(
        "",
        Validators.required
      ),
      vatnum: new FormControl(
        ""
      ),
      name: new FormControl(
        "",
        Validators.required
      ),
      street: new FormControl(
        "",
        Validators.required
      ),
      city: new FormControl(
        "",
        Validators.required
      ),
      zip: new FormControl(
        "",
        Validators.required
      ),
      country: new FormControl(
        CustomUtils.getCountryCode(locale),
        Validators.required
      ),
      emailPrimary: new FormControl(
        this.company?.emailPrimary,
        [Validators.required, Validators.email]
      ),
      emailInvoice: new FormControl(
        this.company?.emailInvoice,
        [Validators.required, Validators.email]
      )
    });

    this.companyFormGroup.valueChanges.subscribe((value) => {
      this.updateDataModel(value);
    });



  }
  ngOnInit() {
    if (!this.company) {
      this.company = new Company("");
      this.isNew = true;
      return
    }

    this.companyFormGroup.get("regnum")?.disable({ emitEvent: false });
    this.companyFormGroup.get("vatnum")?.disable({ emitEvent: false });
    this.companyFormGroup.get("street")?.disable({ emitEvent: false });
    this.companyFormGroup.get("zip")?.disable({ emitEvent: false });
    this.companyFormGroup.get("city")?.disable({ emitEvent: false });
    this.companyFormGroup.get("country")?.disable({ emitEvent: false });
    

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["company"].currentValue) {
      this.company = changes["company"].currentValue;
      this.companyFormGroup.get("name")?.setValue(this.company?.name, { emitEvent: false });
      this.companyFormGroup.get("regnum")?.setValue(this.company?.regnum, { emitEvent: false });
      this.companyFormGroup.get("vatnum")?.setValue(this.company?.vatnum, { emitEvent: false });
      this.companyFormGroup.get("street")?.setValue(this.company?.street, { emitEvent: false });
      this.companyFormGroup.get("zip")?.setValue(this.company?.zip?.toString(), { emitEvent: false });
      this.companyFormGroup.get("city")?.setValue(this.company?.city, { emitEvent: false });
      this.companyFormGroup.get("country")?.setValue(this.company?.country, { emitEvent: false });

      this.companyFormGroup.get("emailPrimary")?.setValue( this.company?.emailPrimary, { emitEvent: false });
      this.companyFormGroup.get("emailInvoice")?.setValue( this.company?.emailInvoice, { emitEvent: false });
    }

    for (let i in this.companyFormGroup.controls) {
      this.companyFormGroup.controls[i].markAsTouched();
    }
    
    setTimeout(() => {
      this.validateForm();
    }, 1)
  }

  get f() {
    return this.companyFormGroup.controls;
  }

  updateDataModel(value: any) {
    
    if (this.company) {
      this.company = Object.assign(this.company, value);
      this.companyUpdate.emit(this.company!);
    }

    setTimeout(() => {
      this.validateForm();
    }, 1)
  }
  validateForm() {
    this.onValidation.emit(this.companyFormGroup.status == "VALID");
  }

  findCompanyByRegnum() {

    let regnum = this.companyFormGroup.get("regnum")?.value;
    regnum = regnum.replace(/\s/g, '').trim();
    this.companyFormGroup.get("regnum")?.setValue(regnum, { emitEvent: false });

    this.companyService.findCompanyByRegnum(regnum).subscribe({
      next: (data: any) => {
        this.companyFormGroup!.get("name")?.setValue(data.data.reginfo.obchodniJmeno);
        this.companyFormGroup!.get("country")?.setValue(data.data.reginfo.sidlo.kodStatu);
        this.companyFormGroup!.get("city")?.setValue(data.data.reginfo.sidlo.nazevObce);
        this.companyFormGroup!.get("street")?.setValue(`${data.data.reginfo.sidlo.nazevUlice ? data.data.reginfo.sidlo.nazevUlice : data.data.reginfo.sidlo.nazevCastiObce} ${data.data.reginfo.sidlo.cisloDomovni}`);
        this.companyFormGroup!.get("zip")?.setValue(data.data.reginfo.sidlo.psc.toString());
        this.companyFormGroup!.get("vatnum")?.setValue(data.data.reginfo.dic);
        this.companyUpdate.emit(this.company!);
      },
      error: (HttpErrorResponse) => {
        console.log(HttpErrorResponse.message);
        this.companyFormGroup.get("regnum")?.setErrors({ 'incorrect': true });
      },
    });
  }

  
}
