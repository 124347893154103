<mat-card class="order-payment-state card card--large card--soft-shadow">
  <mat-card-title><mat-icon color="primary">payments</mat-icon><span i18n>Stav objednávky</span></mat-card-title>
  <div class="order-payment-state__container mb-5" *ngIf="order; else loadingTemplate">

    <div *ngIf="order.orderState == orderStateEnum.PAID">
      <app-pictogram 
        icon="payment-success" 
        [width]="180"  
        template="horizontal-large" 
        [title]="pictogramPaidTitle" 
        [text]="pictogramPaidText" >
      </app-pictogram>
    </div>

    <div *ngIf="order.orderState == orderStateEnum.CREATED">

      <app-pictogram 
        icon="payment-fail" 
        [width]="180"  
        template="horizontal-large" 
        [title]="pictogramFailTitle" 
        [text]="pictogramFailText" >
      </app-pictogram>

    </div>

    <div *ngIf="order.orderState == orderStateEnum.CANCELED">
      <app-pictogram 
        icon="payment-fail" 
        [width]="180"  
        template="horizontal-large" 
        [title]="pictogramFailTitle" 
        [text]="pictogramFailText" >
      </app-pictogram>
    </div>

    <div *ngIf="order.orderState == orderStateEnum.TIMEOUTED">
      <app-pictogram 
        icon="payment-fail" 
        [width]="180"  
        template="horizontal-large" 
        [title]="pictogramTimeoutedTitle" 
        [text]="pictogramTimeoutedText" >
      </app-pictogram>
    </div>

    <div *ngIf="order.orderState == orderStateEnum.REFUNDED">
      <app-pictogram 
        icon="payment-fail" 
        [width]="180"  
        template="horizontal-large" 
        [title]="pictogramRefundTitle" 
        [text]="pictogramRefundText" >
      </app-pictogram>

    </div>
    <div *ngIf="order.orderState == orderStateEnum.PARTIALLY_REFUNDED">
      <app-pictogram 
        icon="payment-fail" 
        [width]="180"  
        template="horizontal-large" 
        [title]="pictogramPartialRefundTitle" 
        [text]="pictogramRefundText" >
      </app-pictogram>
    </div>
  </div>
  <div *ngIf="!isLoading && stateError">
    <app-pictogram 
      icon="payment-fail" 
      [width]="180"  
      template="horizontal-large" 
      [title]="pictogramFailTitle" 
      [text]="pictogramFailText" >
    </app-pictogram>
  </div>
</mat-card>

<ng-template #loadingTemplate>
  <div *ngIf="!stateError" class="flex justify-center items-center flex-col gap-10">
    <app-loading></app-loading>
    <p i18n>Zjišťuji stav objednávky. Prosíme o chvíli strpení...</p>
  </div>
</ng-template>