<div class="order-stepper dialog">
  <div mat-dialog-title class="dialog__title">
    <h2 *ngIf="plan" i18n>Nová objednávka - {{ plan.title }}</h2>
    <button class="dialog__close" mat-icon-button (click)="closeStepper()" i18n-aria-label aria-label="Zavřít dialog">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="order-stepper__stepper" [formGroup]="orderForms">
    <mat-stepper orientation="vertical" linear [@.disabled]="true" #orderStepper class="mat-stepper-vertical--custom">
      
       <!-- Select plan -->
       <mat-step *ngIf="!hasInputPlan"  i18n-label label="Plán" [completed]="isPlanStepCompleted" >
        <ng-template matStepLabel i18n>Plán</ng-template>
        <ng-template matStepContent>
          <div class="order-stepper__plans" *ngIf="!companyRegLoading">
            <app-plans-list (planSelection)="onPlanSelection($event)" [template]="planTemplateEnum.SELECTION"></app-plans-list> 
          </div>

          <div class="dialog__stepper-footer justify-end">
            <button [disabled]="!isPlanStepCompleted"  mat-raised-button class="mat-mdc-raised-button--shadow" color="primary"
               (click)="nextStep()" i18n>Další</button>
          </div>
        </ng-template>
      </mat-step>

      <!-- Select License -->
      <mat-step *ngIf="!hasInputLicense"  i18n-label label="Provozovna" >
        <ng-template matStepLabel i18n>Provozovna</ng-template>
        <ng-template matStepContent>
          <div class="order-stepper__type-switch" *ngIf="!companyRegLoading">
              <mat-radio-group (change)="orderTypeChange($event)"  class="order-stepper__step-container order-stepper__tiles" >
              <div class="order-stepper__tile">
                <app-pictogram [width]="180" icon="new-license"></app-pictogram>
                <mat-radio-button [checked]="orderType == orderTypeEnum.NEW"   [value]="orderTypeEnum.NEW"><h3 i18n>Vytvořit novou provozovnu</h3></mat-radio-button>
              </div>
              
              <div class="order-stepper__tile"> 
                <app-pictogram [width]="180" icon="extend-license"></app-pictogram>
                <mat-radio-button  [checked]="orderType == orderTypeEnum.EXTEND" [value]="orderTypeEnum.EXTEND"><h3 i18n>Prodloužit stávající provozovnu</h3></mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div class="dialog__stepper-footer justify-end">
            <button *ngIf="isPlanStepCompleted" mat-raised-button (click)="previousStep()" i18n>Zpět</button>

            <button  mat-raised-button class="mat-mdc-raised-button--shadow" color="primary"
               (click)="nextStep()" i18n>Další</button>
          </div>
        </ng-template>
      </mat-step>

      <!-- Select/eddit company -->
      <mat-step  i18n-label label="Firemní údaje" [stepControl]="f['isCompanyValid']" [completed]="isCompanyStepCompleted">
        <ng-template matStepLabel i18n>Firemní udaje</ng-template>
        <ng-template matStepContent>
          <div class="order-stepper__forms" *ngIf="!companyRegLoading">
            
            <div class="order-stepper__step-container">
              <div *ngIf="!companyRegError" class="order-stepper__left">
                <mat-form-field class="mat-mdc-form-field--fullwidth ">
                  <mat-label i18n>Objednávku zadává</mat-label>
                  <input matInput i18n-placeholder placeholder="Objednávku zadává" formControlName="orderAuthor">
                </mat-form-field>
                <app-company-registration [company]="company" (companyUpdate)="onCompanyUpdate($event)"
                  (onValidation)="validateCompanyStep($event)"></app-company-registration>
              </div>
              <div class="order-stepper__right">
                <app-pictogram *ngIf="company" [title]="pictogramCompanyTitle" [text]="pictogramCompanyText" [width]="130" icon="license-settings"></app-pictogram>
                <app-pictogram *ngIf="!company" [title]="pictogramCompanyTitle" [text]="pictogramNoCompanyText" [width]="130" icon="license-settings"></app-pictogram>
              </div>
              <div *ngIf="companyRegError">
                <p i18n>Nepodařilo se zaregistrovat Vaši společnost. Zkuste prosím později, nebo kontaktujte podporu na  <a href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a>.</p>
              </div>
            </div>
          </div>
          <div class="order-stepper__saving" *ngIf="companyRegLoading">
            <app-loading></app-loading>
          </div>
          <div class="dialog__stepper-footer justify-end">
            <button *ngIf="isNewCompany && companyRegError" mat-raised-button class="mat-mdc-raised-button--shadow" color="primary"
            (click)="resetCompany()" i18n>Zadat jinou firmu</button>
            <button mat-raised-button (click)="previousStep()" i18n>Zpět</button>

            <button *ngIf="isNewCompany" mat-raised-button class="mat-mdc-raised-button--shadow" color="primary"
              [disabled]="!f['isCompanyValid'].valid || companyRegLoading" (click)="saveCompany()" i18n>Uložit
              firmu</button>
            <button *ngIf="!isNewCompany" mat-raised-button class="mat-mdc-raised-button--shadow" color="primary"
              [disabled]="!f['isCompanyValid'].valid" (click)="nextStep()" i18n>Další</button>
          </div>
        </ng-template>
      </mat-step>

      <!-- extend/create license -->
      <mat-step [stepControl]="f['isProductValid']">
        <ng-template matStepLabel i18n>Produkt</ng-template>
        <ng-template matStepContent>
          <div class="order-stepper__forms" >
            <!--h3 class="mb-5" *ngIf="orderType==null" i18n>Vyberte typ objednávky</h3-->
            <div class="order-stepper__step-container">
              <div  class="order-stepper__left ">
                <div *ngIf="orderType === orderTypeEnum.NEW">
                  <app-order-new *ngIf="plan" [orderItem]="newOrder.orderItems[newOrder.orderItems.length-1]" (orderItemUpdate)="onOrdersItemUpdate($event)"
                    (onValidation)="validateProductStep($event)"></app-order-new>
                </div>

                <div class="h-full" *ngIf="orderType === orderTypeEnum.EXTEND">
                  <app-order-extend [plan]="plan" [orderItems]="newOrder.orderItems"
                    (orderItemUpdate)="onOrdersItemsUpdate($event)"
                    (onValidation)="validateProductStep($event)"></app-order-extend>
                </div>

                <div *ngIf="orderType === orderTypeEnum.CHANGE" class="w-full">
                  <h3 class="mb-5" i18n>Změna licence <span *ngIf="newOrder.orderItems[0].license.plan">na : {{ newOrder.orderItems[0].license.plan.title}}</span></h3>
                  <app-order-change [plan]="plan" [orderItem]="newOrder.orderItems[newOrder.orderItems.length-1]" (orderItemUpdate)="onOrdersItemUpdate($event)"  (onValidation)="validateProductStep($event)"></app-order-change>
                </div>
              </div>

              <div  *ngIf="orderType !== orderTypeEnum.EXTEND" class="order-stepper__right"  >
                <app-pictogram [title]="pictogranLicenseTitle" [text]="pictogramLicenseText" [width]="130" icon="license-settings"></app-pictogram>
              </div>
            </div>

            <div  class="order-stepper__calculator">
              <app-order-calculator *ngIf="f['isProductValid'].valid && newOrder.orderItems.length>0" (onPriceChanged)="onPriceChanged($event)"
                [theme]="'highlighted'" [order]="newOrder"></app-order-calculator>
              <div class="order-stepper__calculator__placeholder" *ngIf="!f['isProductValid'].valid" i18n>
                <span *ngIf="orderType !== orderTypeEnum.EXTEND">Vyplňte informace a my vám vypočítáme cenu.</span>
                <span *ngIf="orderType == orderTypeEnum.EXTEND">Vyberte licence, které chcete prodloužit a my vám vypočítáme cenu.</span>
              </div>
            </div>
          </div>

          <div class="dialog__stepper-footer justify-end">
            <button mat-raised-button (click)="previousStep()" i18n>Zpět</button>

            <button mat-raised-button class="mat-mdc-raised-button--shadow" color="primary"
              [disabled]="!f['isProductValid'].valid" (click)="nextStep()" i18n>Další</button>
          </div>

        </ng-template>
      </mat-step>
      
      <!-- recapitulation -->
      <mat-step i18n-label label="Rekapitulace">
        <ng-template matStepLabel i18n>Rekapitulace</ng-template>
        <ng-template matStepContent>
          <div class="order-stepper__forms h-full  w-full">
              <app-order-recapitulation [order]="newOrder"></app-order-recapitulation>
              <app-order-calculator *ngIf="f['isProductValid'].valid" (onPriceChanged)="onPriceChanged($event)"
                [theme]="'highlighted'" [order]="newOrder"></app-order-calculator>
          </div>
          <div class="dialog__stepper-footer justify-end">
            <button mat-raised-button (click)="previousStep()" i18n>Zpět</button>
            <button mat-raised-button class="mat-mdc-raised-button--shadow" color="primary" (click)="addToCart()"
              i18n>Vložit do košíku</button>
          </div>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </div>
</div>