import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LicensesService } from 'src/app/licenses/licenses.service';
import { License } from 'src/app/models/license.model';
import { RequestResponse } from 'src/app/models/request-status';
import { OrderService } from '../order.service';
import { OrderType } from 'src/app/models/orders.model';

@Component({
  selector: 'app-order-open',
  templateUrl: './order-open.component.html',
  styleUrls: ['./order-open.component.scss']
})
export class OrderOpenComponent {
  queryParams: Params | undefined;
  selectedLicenses: License[] = [];
  licensesIds: string[] = [];

  constructor(private activatedRoute: ActivatedRoute, private licensesService: LicensesService, private orderService: OrderService) {
      this.getRouteParams();
  }
    
  getRouteParams() {
    this.activatedRoute.queryParams.subscribe( params => {
        this.queryParams = params;
        this.licensesIds = this.queryParams["licenseids"].split(",");
        this.fetchLicenses();
    });
  }

  fetchLicenses() {
    this.licensesService.fetchLicenses().subscribe({
      next: (data: RequestResponse) => {
        if (data.data.licenses.length > 0) {
          this.selectedLicenses = [];
          let firstPlan = data.data.licenses[0].plan.planType;
          this.licensesIds.forEach((id: string) => {
            this.selectedLicenses.push( data.data.licenses.find((license: License) => license.id == id  && license.plan?.planType == firstPlan )); // 
          })

          this.extendLicense(this.selectedLicenses);
        }
      },
      error: (HttpErrorResponse) => {
        console.log(HttpErrorResponse.message);
      },
    });
  }
  extendLicense(licenses: License[]) {
    //this.orderService.openExtendDialog( licenses);
    let orderInputData = {
      licenses: licenses,
      orderType: OrderType.EXTEND,
    }
    this.orderService.openOrderStepperDialog(orderInputData);
  }
}
