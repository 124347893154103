<div class="cart-list" tabindex="0" *ngIf="order; else empty">
  <div class="cart-list__wrapper">
    <table mat-table [dataSource]="order.orderItems">

      <ng-container matColumnDef="orderType">
        <th mat-header-cell *matHeaderCellDef i18n>Typ</th>
        <td mat-cell *matCellDef="let item" style="width: 120px;">
          <span *ngIf="item.orderType == orderTypeEnum.EXTEND" i18n>Prodloužení</span>
          <span *ngIf="item.orderType == orderTypeEnum.NEW" i18n>Nová</span>
          <span *ngIf="item.orderType == orderTypeEnum.CHANGE" i18n>Změna</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="licenseName">
        <th mat-header-cell *matHeaderCellDef i18n>Licence</th>
        <td mat-cell *matCellDef="let item" i18n> {{ item.license.plan.title }} na {{ item.license.subscriptionLength |
          OrderMonths}} </td>
      </ng-container>

      <ng-container matColumnDef="playerName">
        <th mat-header-cell *matHeaderCellDef i18n>Název</th>
        <td mat-cell *matCellDef="let item"> {{ item.license.player.name}}</td>
      </ng-container>

      <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef i18n>Plocha</th>
        <td mat-cell *matCellDef="let item"> {{ item.license.player.area}} m2</td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef i18n>Začátek licence</th>
        <td mat-cell *matCellDef="let item"> {{item.license.startDate | date }} </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef i18n>Konec licence</th>
        <td mat-cell *matCellDef="let item"> {{item.license.endDate | date }} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="text-align: right; width: 70px;"> </th>
        <td mat-cell *matCellDef="let item; let i = index" style="text-align: right; width: 70px;">
          <button (click)="removeItemFromCart(i)" color="primary" class="cart-list__action"
            mat-icon-button><mat-icon>delete</mat-icon></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="priceWithVat">
        <th mat-header-cell *matHeaderCellDef i18n style="text-align: right;" i18n>Cena s DPH</th>
        <td mat-cell *matCellDef="let item" style="text-align: right;">
          <div class="cart-list__discount" *ngIf="item.priceSummary">
            <span style="text-decoration: line-through">{{ (item.priceSummary.basePrice * getVatMultiplayer(vatRate)) |
              currency:order.currency }}</span>
            <span class="cart-list__discount-title" *ngIf="item.priceSummary.discountPer" i18n> - {{
              item.priceSummary.discountPer }} % sleva</span>
          </div>
          <div class="flex gap-2 justify-end">
            <span> {{ (item.price * getVatMultiplayer(vatRate)) | currency:order.currency}} </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="vat">
        <th mat-header-cell *matHeaderCellDef i18n style="text-align: center; width:80px">DPH</th>
        <td mat-cell *matCellDef="let item" style="text-align: center; width:80px"> {{vatRate}} % </td>
      </ng-container>


      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef i18n style="text-align: right; " i18n>Cena bez DPH</th>
        <td mat-cell *matCellDef="let item" style="text-align: right; ">

          <div class="cart-list__discount" *ngIf="item.priceSummary">
            <span style="text-decoration: line-through">{{ item.priceSummary.basePrice | currency:order.currency }}</span>
            <span class="cart-list__discount-title" *ngIf="item.priceSummary.discountPer" i18n> - {{
              item.priceSummary.discountPer }} % sleva</span>
          </div>
          <div class="flex gap-2 justify-end">
            <span> {{item.price | currency:order.currency}} </span>
          </div>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-divider></mat-divider>
  <div class="cart-list__discount-row" *ngIf="discountCoupon">
    <div></div>
    <div class="cart-list__discount-column">
      <span i18n>Sleva po uplatnění kódu: </span><span> {{ discountCoupon._id }}</span>
    </div>
    <div class="cart-list__discount-column cart-list__discount-type">
      <div *ngIf="discountCoupon.type=='PERCENT'">
        <span> {{ discountCoupon.value }}</span><span>%</span>
      </div>
      <div *ngIf="discountCoupon.type=='FIXED'">
        <span> -{{ discountCoupon.value[order.currency] | currency:order.currency }}</span>
      </div>
    </div>
  </div>
  <mat-divider *ngIf="discountCoupon"></mat-divider>
  <div class="cart-list__discount-code">
    <button *ngIf="!hasDiscountCode" style="width: 220px;" mat-stroked-button (click)="showDiscountCode()" i18n>Uplatnit slevový
      kód</button>
    <div [hidden]="!hasDiscountCode">
      <mat-form-field style="width: 250px;">
        <mat-label i18n>Slevový kód</mat-label>
        <input [formControl]="discountCodeForm" matInput i18n-placeholder placeholder="Slevový kód">
        <button *ngIf="discountCodeForm.value !=''" matSuffix mat-icon-button aria-label="Clear"
          (click)="resetDiscount()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="discountCodeForm.hasError('incorrect')" i18n>{{ couponErrorMessage }}</mat-error>
      </mat-form-field>
    </div>
    <button [hidden]="!hasDiscountCode" style="width: 150px;" color="primary" mat-raised-button
      (click)="activateDiscount()" i18n>Aktivovat</button>
  </div>
  <div class="cart-list__summary mt-5 mr-20">
    <app-order-calculator (onPriceChanged)="onPriceChanged($event)" [order]="order"
      [discountCoupon]="discountCoupon"></app-order-calculator>
  </div>
  <div class="flex justify-between flex-1 mt-10">
    <button *ngIf="order.orderItems.length > 0" mat-raised-button (click)="clearCart()" i18n>Vyprázdnit košík</button>
    <button *ngIf="order.orderItems.length > 0" mat-raised-button (click)="payOrder()" color="primary"
      i18n>Zaplatit</button>
  </div>
</div>

<ng-template #empty>
  <app-pictogram [width]="180" (onClick)="openBenefitsDetail()" buttonText="Projít výhody" template="horizontal" [title]="pictogramEmptyTitle" [text]="pictogramEmptyText" icon="empty-cart"></app-pictogram>
</ng-template>