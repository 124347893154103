<div class="order-recapitulation">
  <div class="order-recapitulation__summary" *ngIf="order">
    <div *ngIf="order.orderItems.length > 0" class="order-recapitulation__order-list">
        <mat-card *ngFor="let orderItem of order.orderItems" class="mdc-card--blue-shadow order-recapitulation__order-item">
            <mat-card-content>
              <h3 >{{ orderItem.license.plan!.title }}</h3>
              <div class="order-recapitulation__row" *ngIf="orderItem.license.player">
                <p  class="order-recapitulation__item-title" i18n>Název: </p>
                <p class="order-recapitulation__item-value">{{ orderItem.license.player.name }}</p>
              </div>
              <div class="order-recapitulation__row">
                <p class="order-recapitulation__item-title"  i18n>Délka licence: </p>
                <p class="order-recapitulation__item-value">{{ orderItem.license.subscriptionLength | OrderMonths }}</p>
              </div>
              <div class="order-recapitulation__row">
                <p class="order-recapitulation__item-title"  i18n>Cena licence: </p> 
                <div class="flex  gap-2">
                  <div class="flex gap-2">
                    <span class="order-recapitulation__price-total"> {{orderItem.price  | currency:order.currency}} </span>
                  </div>
                  <div class="order-recapitulation__discount" *ngIf="orderItem.priceSummary?.discountPer">
                    <span style="text-decoration: line-through">{{ orderItem.priceSummary?.basePrice  | currency:order.currency }}</span>
                    <span class="order-recapitulation__discount-title" *ngIf="orderItem.priceSummary?.discountPer" > - {{ orderItem.priceSummary?.discountPer }} %</span> 
                  </div>
                </div>
              </div>
              <div *ngIf="orderItem.orderType === orderTypeEnum.NEW" class="order-recapitulation__row">
                <p class="order-recapitulation__item-title"  i18n>Konec Licence: </p>
                <div class="flex">
                  <p *ngIf="orderItem.newLicense?.endDate" class="order-recapitulation__item-value" i18n>{{ orderItem.newLicense?.endDate | date }}</p>
                </div>
              </div>
              <div *ngIf="orderItem.orderType === orderTypeEnum.EXTEND" class="order-recapitulation__row">
                <p class="order-recapitulation__item-title"  i18n>Zarovnat ke stejnému datumu: </p>
                <div class="flex">
                  <p class="order-recapitulation__item-value" ><span *ngIf="orderItem.license.subscriptionAlign" i18n>ANO</span><span *ngIf="!orderItem.license.subscriptionAlign" i18n>NE</span></p>
                  <p *ngIf="orderItem.license.alignDate" class="order-recapitulation__item-value" i18n><span>&nbsp;k&nbsp;</span>{{ orderItem.license.subscriptionAlign ? (orderItem.license.alignDate | date) : (orderItem.license.endDate | date) }}</p>
                </div>
              </div>
              <div *ngIf="orderItem.orderType === orderTypeEnum.EXTEND" class="order-recapitulation__row">
                <p class="order-recapitulation__item-title"  i18n>Konec Licence: </p>
                <p *ngIf="orderItem.newLicense?.endDate" class="order-recapitulation__item-value" i18n>{{ orderItem.newLicense?.endDate | date }}</p>
              </div>
            </mat-card-content>
        </mat-card>
    </div>
  </div>

</div>
