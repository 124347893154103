
<div  class="homepage__show" [class.is-active]="!isNavOpen">
    <button (click)="toggleNav()"   mat-button color="white" ><mat-icon class="mat-icon--white">menu</mat-icon><span>Menu</span></button>
</div>
<div  class="homepage__hide" [class.is-active]="isNavOpen" >
  <button   class="mat-mdc-mini-fab--white"  (click)="toggleNav()"  mat-mini-fab  >
      <mat-icon color="primary" class="mat-icon--white">menu_open</mat-icon>
  </button>
</div>
<div class="homepage__topbar" [class.hidden-nav]="!isNavOpen">
  <app-top-bar></app-top-bar>
</div>
<mat-drawer-container class="homepage"  >
    <mat-drawer class="homepage__menu flex flex-colum justify-between" #drawer mode="side" [opened]="isNavOpen" >
        
        <div class="homepage__content" >
            <div class="homepage__company">
                <div class="homepage__company-select" *ngIf="userService.hasRole('admin') && this.companyService.companyId">
                  <app-company-select [companyId]="companyService.companyId"></app-company-select>
                </div>
            </div>
            <div class="homepage__navigation flex flex-column flex-1">
              <app-main-navigation class="main-navigation" ></app-main-navigation>
            </div>
            <div class="homepage__menu-footer">
                <img class="homepage__product-logo" src="assets/img/logos/mediacloud_logo_RGB.svg">
              <div class="homepage__contact">
                <a (focus)="sendDataLayer('click_mail')" href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a>
                <a (focus)="sendDataLayer('click_phone')" href="tel:+420603910110">+420 603 910 110</a>
              </div>
              <a class="homepage__agreements"  target="_blank"  href="assets/documents/obchodni_podminky.pdf">
                <mat-icon>download</mat-icon>
                <span i18n>Obchodní podmínky</span>
              </a> 
          </div>
        </div>
        
    </mat-drawer>
    <mat-drawer-content class="homepage__content" [class.hasMenuToggle]="!isNavOpen">
            <div class="homepage__container">
                <router-outlet name="content"></router-outlet>
            </div>
            <router-outlet name="dialog"></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>
