<mat-card class="user-settings card card--large card--soft-shadow">

    <mat-card-title><mat-icon color="primary">manage_accounts</mat-icon><span i18n>Nastavení uživatele</span></mat-card-title>
    <div class="user-settings__container">
      <form [formGroup]="userFormGroup" class="user-settings__form" >
        <mat-accordion class="user-settings__accordion" >
          <mat-expansion-panel [expanded]="true" >
            <mat-expansion-panel-header>
              <mat-panel-title><mat-icon>edit</mat-icon><p i18n>Obecné nastavení</p></mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
  
            <div class="u__form-row" >
              <mat-form-field class="u__flex--halfrow" >
                <mat-label i18n>Jméno</mat-label>
                <input matInput i18n-placeholder placeholder="Jméno" formControlName="firstname"  >
              </mat-form-field>
              <mat-form-field class="u__flex--halfrow"  >
                <mat-label i18n>Příjmení</mat-label>
                <input matInput i18n-placeholder placeholder="Příjmení" formControlName="lastname" >
              </mat-form-field>
            </div>
            <mat-form-field class="u__form-row" >
              <mat-label i18n>Email</mat-label>
              <input  type="email" matInput i18n-placeholder placeholder="Email" formControlName="email" >
            </mat-form-field>
  
            <mat-action-row class="user-settings__actions">
              <button color="transparent" (click)="passwordChange()"  mat-raised-button i18n>Změna hesla</button>
              <button (click)="updateUserData()" color="primary" mat-raised-button i18n>Uložit změny</button>
            </mat-action-row>
          </mat-expansion-panel>
        
        </mat-accordion>

      </form>
      <app-pictogram 
      icon="settings" 
      [width]="180"  
      template="vertical" 
      [title]="pictogramSettingsTitle" 
      [text]="pictogramSettingsText" >
    </app-pictogram>
      
    </div>
</mat-card>