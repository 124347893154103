<div class="company-new-modal dialog">
  <div mat-dialog-title class="dialog__title">
    <h2  i18n>Firemní údaje</h2>
    <button class="dialog__close" mat-icon-button  mat-dialog-close i18n-aria-label aria-label="Zavřít dialog">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div *ngIf="!companyRegError"  class="company-new-modal__content dialog__content">

    <app-pictogram icon="edit-company" [width]="180"  template="vertical" [title]="pictogramNoCompanyTitle" [text]="pictogramNoCompanyText" ></app-pictogram>

    <app-company-registration (onValidation)="validateCompany($event)"  (companyUpdate)="onCompanyUpdate($event)"></app-company-registration>
   </div>
   <div *ngIf="companyRegError" class="dialog__content mb-10 flex">
    <p>Nepodařilo se zaregistrovat vaši společnost. Zkuste prosím později nebo kontaktujte podporu na  <a href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a>.</p>
  </div>
  <div class="dialog__footer dialog__footer--reverse">
    <button [disabled]="!isValid"  mat-raised-button class="mat-mdc-raised-button--shadow" color="primary"
      (click)="saveCompany()" i18n>Uložit údaje</button>
  </div>
</div>