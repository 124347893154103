<mat-card class="company-settings card card--large card--soft-shadow">
  <mat-card-title>
    <mat-icon color="primary">settings_applications</mat-icon>
    <span i18n>Nastavení společnosti</span>
  </mat-card-title>
  <div class="company-settings__container flex" *ngIf="companySettings">
    <form [formGroup]="companyFormGroup" class="company-settings__forms" >

      <mat-accordion class="company-settings__accordion" >
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header>
            <mat-panel-title><mat-icon>edit</mat-icon><p i18n>Obecné nastavení</p></mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>

          <div class="company-settings__form-group"> 
            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n> Název společnosti</mat-label>
              <input matInput i18n-placeholder placeholder="Jméno" formControlName="name" >
            </mat-form-field>
          </div>
          <div class="company-settings__form-group"> 
            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n>IČO</mat-label>
              <input matInput i18n-placeholder placeholder="IČO" formControlName="regnum" >
            </mat-form-field>
            
            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n>DIČ</mat-label>
              <input matInput i18n-placeholder placeholder="DIČ" formControlName="vatnum" >
            </mat-form-field>
          </div>
          <div class="company-settings__form-group"> 
            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n>Ulice</mat-label>
              <input type="text" matInput i18n-placeholder placeholder="Ulice" formControlName="street" >
            </mat-form-field>

            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n>Město</mat-label>
              <input  type="text" matInput i18n-placeholder placeholder="Město" formControlName="city" >
            </mat-form-field>

            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n>PSČ</mat-label>
              <input matInput i18n-placeholder placeholder="PSČ" formControlName="zip" >
            </mat-form-field>
          </div>
          <div class="company-settings__form-group"> 
            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n>Kontaktní email</mat-label>
              <input  type="email" matInput i18n-placeholder placeholder="Kontaktní email" formControlName="emailPrimary" >
            </mat-form-field>

            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n>Fakturační email</mat-label>
              <input matInput i18n-placeholder placeholder="Fakturační email" formControlName="emailInvoice" >
            </mat-form-field>
          </div>
          <div class="company-settings__form-group flex-col mb-5"> 
            <h4 i18n>Logo společnosti</h4>
            <div>
              <app-file-upload [preview]="companySettings.logo?.toString()" i18n-placeholder placeholder="Nahrejte nové logo" (fileChange)="onLogoChange($event)"  [progress]="progress"></app-file-upload>
            </div>
          </div>

          <mat-action-row>
            <button (click)="updateCompanyData()" color="primary" mat-raised-button i18n>Uložit změny</button>
          </mat-action-row>
        </mat-expansion-panel>
      
      </mat-accordion>
    </form>
   
    <app-pictogram 
      icon="edit-company" 
      [width]="180"  
      template="vertical" 
      [title]="pictogramEditCompanyTitle" 
      [text]="pictogramEditCompanyText" >
    </app-pictogram>

   
  </div>
    
  <div *ngIf="!companySettings">
    <app-pictogram [width]="180" (onClick)="openCompanyRegistration()" buttonText="Nastavit" template="horizontal" [title]="pictogramNoCompanyTitle" [text]="pictogramNoCompanyText" icon="no-company"></app-pictogram>
  </div>
</mat-card>
