import * as dayjs from 'dayjs';

export class CustomUtils {
  public static isResonsive = false;
  public static urlRegex = new RegExp(
    /(https|rtsp)?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  );
  public static urlRegex2 = new RegExp(
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/
  );
  public static getRandomColor() {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  public static isMobileVersion() {
    let screenWidth = window.innerWidth;
    if (screenWidth < 1024) {
      this.isResonsive = true;
      return true;
    } else {
      this.isResonsive = false;
      return false;
    }
  }
  public static detectMobileVersion() {
    this.isMobileVersion();
  }
  public static generateNewIdInArray(array: any): number {
    let lastId = 0;
    array.forEach((item: any) => {
      if (item.id >= lastId) {
        lastId = item.id;
      }
    });
    return lastId + 1;
  }
  public static formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }
    return value;
  }

  public static formatNumberLabel(value: number | null) {
    if (!value) {
      return 0;
    }
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }
    return value;
  }
  public static getActualTime(): string {
    const today = new Date();
    const time = today.getHours() + ':' + today.getMinutes();
    return time;
  }

  public static getActualDate(): string {
    const today = new Date();
    const time =
      today.getDate() +
      '.' +
      (today.getMonth() + 1) +
      '.' +
      today.getFullYear();
    return time;
  }

  public static getTimeFromIsoString(time: string): string {
    if (time) {
      const date = new Date(time);
      return this.fixDecimalZero(date.getHours() + ':' + date.getMinutes());
    } else {
      return '00:00';
    }
  }

  public static setTimeToIsoDate(date: any, time: any): string {
    var timeArray = time.split(':');
    let dateResult = dayjs(date).hour(timeArray[0]).minute(timeArray[1]);
    return dateResult.toISOString();
  }

  public static addhttp(url: string) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = 'http://' + url;
    }
    return url;
  }
  public static fixDecimalZero(time: string) {
    var res = time.split(':');
    return res[0].padStart(2, '0') + ':' + res[1].padStart(2, '0');
  }

  public static scaleIndexByParent(
    parentSize: number,
    currentSize: number
  ): number {
    return parentSize / currentSize;
  }

  public static rescaleIndexByChild(
    parentSize: number,
    currentSize: number
  ): number {
    return parentSize / currentSize;
  }

  public static timeToSeconds(time: string) {
    const timeArray = time.split(':').map(Number);
    const seconds: number = timeArray[0] * 3600 + timeArray[1] * 60;
    return seconds;
  }

  public static durationToWidth(duration: number) {
    const totalSeconds = Math.round(duration);
    return totalSeconds;
  }

  public static testUrl(url: string): boolean {
    return CustomUtils.urlRegex2.test(url);
  }

  public static getLastId(array: any): number {
    let id = 0;
    array.forEach((element: any) => {
      if (element.id > id) {
        id = element.id;
      }
    });
    return id;
  }

  public static getBrowserLanguageCode(): string {
    let shortLang;
    let lang = window.navigator.languages ? window.navigator.languages[0] : '';
    lang = lang || window.navigator.language;

    shortLang = lang;
    if (shortLang.indexOf('-') !== -1) shortLang = shortLang.split('-')[0];

    if (shortLang.indexOf('_') !== -1) shortLang = shortLang.split('_')[0];
    return shortLang;
  }

  public static getTimeObjectFromTime(time: string): dayjs.Dayjs {
    let dateNow = dayjs();
    let timeArray = time.split(':');
    return dateNow.hour(parseInt(timeArray[0])).minute(parseInt(timeArray[1]));
  }

  public static fixPhpDate(date: string) {
    if (date.includes('+')) {
      const timezoneTime = date.split('+');
      const sign = date.charAt(date.length - 6);
      const timezoneHour = parseInt(sign + timezoneTime[1], 10);
      date = dayjs(date).add(timezoneHour, 'hour').toISOString();
    }
    return date;
  }
  public static getRemaingDays(startDate: string, months: number): number {
    const date1 = dayjs(startDate);
    const date2 = date1.add(months, 'month');
    const date3 = dayjs();
    let difDays = date2.diff(date3, 'day');
    if (difDays < 0) difDays = 0;
    return difDays;
  }
  public static getDaysDifference(startDate: string, endDate: string): number {
    const date1 = dayjs(startDate);
    const date2 = dayjs(endDate);
    let difDays = date1.diff(date2, 'day');
    return difDays;
  }

  public static NumberLongToDate(number: string):string {
    return new Date(parseInt(number)).toLocaleString();
  }
  public static getLanguageMutation(object: any, langCode: string ) {
    return object.find((item: any) => item.lang == langCode);
  }
  public static getPercentMultiplayer(percent: number ) {
    return 1 + (percent/100);
  }

  public static getCountryCode(locale: string) {
    let countryCode;
    switch(locale) {
      case "cs-CZ" :
        countryCode = "CZ";
      break;
      case "pl-PL" :
        countryCode = "PL"
      break;
      default:
        countryCode = "EN"
    }
    return countryCode;
  }

  public static getCountryTitleByCode(code: string) {
    let countryTitle;
    switch(code) {
      case "CZ" :
        countryTitle = "Česko";
      break;
      case "PL" :
        countryTitle = "Polska"
      break;
      default:
        countryTitle = "Česko";
    }
    return countryTitle;
  }

  public static getLanguageTitleByIsoCode(code: string) {
    let countryTitle;
    switch(code) {
      case "cs" :
        countryTitle = "Čestina";
      break;
      case "pl" :
        countryTitle = "Polski"
      break;
      case "en" :
        countryTitle = "English"
      break;
      default:
        countryTitle = "Česko";
    }
    return countryTitle;
  }
  public static getLanguageCodeByIsoCode(code: string) {
    let countryTitle;
    switch(code) {
      case "cs" :
        countryTitle = "CZE";
      break;
      case "pl" :
        countryTitle = "PL"
      break;
      case "en" :
        countryTitle = "ENG"
      break;
      default:
        countryTitle = "Česko";
    }
    return countryTitle;
  }

  public static getCurencyByLocale(locale: string) {
    let countryTitle;
    switch(locale) {
      case "cs" :
        countryTitle = "CZK";
      break;
      case "pl" :
        countryTitle = "EUR"
      break;
      case "en" :
        countryTitle = "EUR"
      break;
      default:
        countryTitle = "CZK";
    }
    return countryTitle;
  }
}
