import { Component, EventEmitter, Input,  Output,  SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-pictogram',
  templateUrl: './pictogram.component.html',
  styleUrls: ['./pictogram.component.scss'],
    encapsulation: ViewEncapsulation.None,
  
})
export class PictogramComponent {
  @Input() icon: string = "";
  @Input() width?: number = 150;
  @Input() template?: string = "vertical";
  @Input() title?: string = "";
  @Input() text?: string = "";
  @Input() buttonText?: string = "";
  @Output() onClick = new EventEmitter<any>();

  constructor() {}

  ngOnChanges(change: SimpleChanges) {

    if(change['icon']) {
      this.icon = change['icon'].currentValue;

    }

    if(change['width']) {
      this.width = change['width'].currentValue;
    }
  }

}
