import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../order.service';
import { RequestResponse } from 'src/app/models/request-status';
import { Order, OrderState } from 'src/app/models/orders.model';

@Component({
  selector: 'app-order-payment-state',
  templateUrl: './order-payment-state.component.html',
  styleUrls: ['./order-payment-state.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderPaymentStateComponent {
  orderId: string | null = null;
  order: Order | null = null;
  orderStateEnum: typeof OrderState = OrderState;
  isLoading = false;
  requestInterval: any = null;
  timeout: any = null;
  stateError = false;

  pictogramPaidTitle = $localize`Povedlo se`;
  pictogramPaidText = $localize`Platba byla uspešně provedena a děkujeme za vaši objednávku.`;

  
  pictogramFailTitle = $localize`Nepovedlo se`;
  pictogramFailText = $localize`Objednávka není zaplacena. Prosím opakujte platbu v sekci objednávky.<br>Pokud potíže přetrvávají, kontaktujte podporu na: <a href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a>`;

  pictogramTimeoutedTitle = $localize`Nepovedlo se`;
  pictogramTimeoutedText = $localize`Platnost platební brány vypršela. Prosím opakujte platbu v sekci objednávky.<br>Pokud potíže přetrvávají, kontaktujte podporu na: <a href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a>`;

  pictogramRefundTitle = $localize`Vrácení platby`;
  pictogramRefundText = $localize`Platba za objednávku již byla, nebo bude vrácena v nejbližší době.`;

  pictogramPartialRefundTitle = $localize`Částečné Vrácení platby`;


  constructor(private activatedRoute: ActivatedRoute, private orderService: OrderService) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['orderId']) {
        this.orderId = params['orderId'];
      }
    });
  }
  ngOnInit() {
    if (this.orderId) {
      this.isLoading = true;


      this.requestInterval = setInterval(() => {
        this.fetchOrderDetail(this.orderId!);
      }, 3000)

      this.timeout = setTimeout(() => {
        clearInterval(this.requestInterval);
        this.stateError = true;
        this.isLoading = false;
      }, 30000)
    }
  }
  fetchOrderDetail(id: string) {
    this.orderService.fetchOrderDetail(id).subscribe({
      next: (data: RequestResponse) => {
        if (data)
          
          if (data.data.order.orderState == this.orderStateEnum.CREATED) {
            return
          }

          this.order = data.data.order;
        
        this.isLoading = false;
        clearInterval(this.requestInterval);
        clearTimeout(this.timeout);
      },
      error: (HttpErrorResponse) => {
        console.log(HttpErrorResponse.message);
      },
    })
  }
}
