import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/models/user.model';
import { UsersService } from '../users.service';
import { UserService } from 'src/app/services/user.service';
import { SaveSnackbarComponent } from 'src/app/utils/save-snackbar/save-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserPasswordChangeComponent } from '../user-password-change/user-password-change.component';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { PassMismatchValidator } from 'src/app/shared/pass-mismatch.directive';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserSettingsComponent {
  @Input() userId?: User | null = null;
  userFormGroup: FormGroup;
  userSettings: User | null = null;
  pictogramSettingsTitle = $localize`Vaše osobní údaje`;
  pictogramSettingsText = $localize`Prosím nastavte veškeré své údaje pro snadnější komunikaci s Vámi.`;


  constructor( private authorizationService: AuthorizationService, public dialog: MatDialog, private snackBar: MatSnackBar, private fb: FormBuilder, private usersService: UsersService, private userService: UserService) {
    this.fetchUserDetail();

    this.userFormGroup = this.fb.group({
      firstname: new FormControl(""),
      lastname: new FormControl(""),
      email: new FormControl({value: "", disabled: true}),
      password: new FormControl(''),
      newPassword: new FormControl('', {
        validators: [Validators.required, Validators.minLength(8)],
        updateOn: 'change',
      }),
      confirmPassword: new FormControl('', {
        validators: [Validators.required, PassMismatchValidator('newPassword')],
        updateOn: 'change',
      }),
    });

    
    this.userFormGroup.valueChanges.subscribe((value) => {
      this.updateDataModel(value);
    });
  }

  updateDataModel(value: any) {
    if(this.userSettings) {
      this.userSettings = Object.assign(this.userSettings, value);
    }
  }

  fetchUserDetail() {
    if(this.userService.userISUID) {
      this.usersService.fetchUserDetail(this.userService.userISUID).subscribe({
        next: (data) => {
          if (data) {
            this.userSettings = data.data.person;
            this.userFormGroup.get("firstname")?.setValue(this.userSettings?.firstname,{emitEvent: false} );
            this.userFormGroup.get("lastname")?.setValue(this.userSettings?.lastname, {emitEvent: false});
            this.userFormGroup.get("email")?.setValue(this.userSettings?.email, {emitEvent: false});
          }
        },
        error: (HttpErrorResponse: any) => {
          console.log(HttpErrorResponse.message);
        },
      })
    }
  }

  updateUserData() {
    if(this.userService.userISUID && this.userSettings) {
      let userData = {
        firstname: this.userFormGroup.get("firstname")?.value,
        lastname: this.userFormGroup.get("lastname")?.value,
      }

      this.usersService.updateUserDetail(this.userService.userISUID, userData).subscribe({
        next: (data) => {
          this.snackBar.openFromComponent(SaveSnackbarComponent, {
            duration: 4000,
          });

          this.userService.updatePerson(this.userSettings as User);
        },
        error: (HttpErrorResponse: any) => {
          console.log(HttpErrorResponse.message);
        },
      })
    }
  }
  passwordChange() {
      this.dialog.open(UserPasswordChangeComponent, {
        disableClose: true,
        width: '500px',
        maxHeight: '100vh',
        maxWidth: '95vw',
        panelClass: 'company-new__dialog'
      });
  }

  init2FA() {
    this.usersService.init2FA().subscribe({
      next: (data)=> {
        console.log(data);
      },
      error: (error)=> {
        console.log(error)
      }
    })
  }
}
