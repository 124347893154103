import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { CartService } from 'src/app/cart/cart.service';
import { OrderAddedComponent } from '../order-added/order-added.component';
import { Order, OrderItem, OrderPrice, OrderStepperInput, OrderType } from 'src/app/models/orders.model';
import { CompanyService } from 'src/app/company/company.service';
import { Company } from 'src/app/models/company.model';
import { UserService } from 'src/app/services/user.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject, LOCALE_ID } from '@angular/core';
import { PlanItem, PlanTemplate } from 'src/app/models/plan.model';
import { License, LicenseType } from 'src/app/models/license.model';
import { RequestResponse } from 'src/app/models/request-status';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyErrorComponent } from 'src/app/company/company-error/company-error.component';
import { Player, PlayerCategory } from 'src/app/models/player.model';
import { CustomUtils } from 'src/app/utils/custom-utils.component';

@Component({
  selector: 'app-order-stepper',
  templateUrl: './order-stepper.component.html',
  styleUrls: ['./order-stepper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderStepperComponent {
  newOrder: Order;

  company: Company | null = null;
  orderForms: FormGroup;
  plan: PlanItem | null = null;
  planTemplateEnum: typeof PlanTemplate = PlanTemplate
  availablePlans: PlanItem[] = [];

  orderType?: OrderType;
  orderTypeEnum: typeof OrderType = OrderType;

  playerCategory?: PlayerCategory;
  playerCategoryEnum: typeof PlayerCategory = PlayerCategory;
  //validation
  isCompanyStepCompleted = false;
  isPlanStepCompleted = false;
  isNewCompany = false;
  trigger: number = 0;
  companyRegLoading = false;
  companyRegError = false;
  hasInputLicense = false;
  hasInputPlan = false;
  pictogramCompanyTitle = $localize`Vaše údaje o společnosti`;
  pictogramCompanyText = $localize`Zkontrolujte prosím správnost údajů pro vytvoření licenční smlouvy.`;
  pictogramNoCompanyText = $localize`Zadejte prosím údaje o Vaší společnosti, abychom mohli vytvořit licenční smlouvu.`;

  pictogranLicenseTitle = $localize`Nová licence`;
  pictogramLicenseText = $localize`Vyplňte údaje o prodejně, abychom Vám mohli připravit nejlepší nabídku.`;


  @ViewChild('orderStepper') private orderStepper: MatStepper | undefined;

  constructor(
    private companyService: CompanyService,
    private cartService: CartService,
    private userService: UserService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<OrderStepperComponent>,


    
    @Inject(MAT_DIALOG_DATA) private data: any,
    @Inject(LOCALE_ID) public locale: string
  ) {

    this.company = this.companyService.activeCompany;
    this.isNewCompany = this.company ? false : true; // opens company registration during order
   
    this.newOrder = new Order();
    this.newOrder.lang = locale;

    //get currency from company otherwise get currency by locale
    this.newOrder.currency = this.userService.activeCompanyCurrency ? this.userService.activeCompanyCurrency : CustomUtils.getCurencyByLocale(locale);


    switch(data.inputData.orderType) {
      case(OrderType.NEW):
        this.orderType = OrderType.NEW
      break

      case(OrderType.EXTEND):
        this.orderType = OrderType.EXTEND
        this.hasInputLicense = true
      break

      case(OrderType.CHANGE):
        this.orderType = OrderType.CHANGE
      break

      case(OrderType.TOSELECT):
        this.orderType = OrderType.NEW // select default value for radio buttons
      break
    }


    if(data.inputData.plan) {

      this.hasInputPlan = true;
      this.plan = data.inputData.plan;

      // for new licence create empty from passed plan
      if (this.orderType === OrderType.NEW ) {
        this.createOrderItemByPlan(data.inputData.plan);
      }
    }

    if (data.inputData.licenses.length > 0) {
      this.plan = data.inputData.licenses[0].plan as PlanItem;
      this.playerCategory = data.inputData.licenses[0].playerCategory as PlayerCategory;
      
      this.newOrder.orderItems = data.inputData.licenses.map((license: License) => {
        license = JSON.parse(JSON.stringify(license));
        let newOrderItem = new OrderItem(license);
        newOrderItem._orderItemId = this.newOrder.orderItems.length;
        newOrderItem.license.plan = license.plan
        newOrderItem.license.companyISUID = this.companyService.companyId;
        newOrderItem.license.planId = license.planId;
        newOrderItem.orderType = this.orderType as OrderType;
        return newOrderItem;
      });
      this.orderStepper?.next();
    }

    this.orderForms = this.fb.group({
      orderType: new FormControl(this.orderType, Validators.required),
      orderAuthor: new FormControl(
        this.userService.fullName,
        Validators.required
      ),
      isPlanValid: new FormControl(false, Validators.requiredTrue),
      isProductValid: new FormControl(false, Validators.requiredTrue),
      isCompanyValid: new FormControl(false, Validators.requiredTrue),
    });
    
  
    this.orderForms.valueChanges.subscribe((value) => {
      this.updateDataModel(value);
    });
  }
 
  orderTypeChange(event: any) {
    this.orderType = event.value;

    // if passed plan create new order
    if (this.orderType === OrderType.NEW && this.plan) {
      this.createOrderItemByPlan(this.plan);
    }

    // if passed plan and change type to extend clear items to select existing license
    if (this.orderType === OrderType.EXTEND && this.plan ) {
      this.newOrder.orderItems = [];
    }
  }

  createOrderItemByPlan(plan: PlanItem) {
    this.playerCategory = plan.playerCategory as PlayerCategory;
    let newOrderItem = new OrderItem(new License(plan.id, this.playerCategory));
    newOrderItem._orderItemId = this.newOrder.orderItems.length;
    newOrderItem.license.plan = plan;
    newOrderItem.license.player = new Player();
    newOrderItem.orderType = this.orderType as OrderType;
    this.newOrder.orderItems.push(newOrderItem);
  }

  onPlanSelection(plan: PlanItem) {
    if(!plan) return ;

    this.plan = plan;
    this.isPlanStepCompleted = true;
    this.newOrder.orderItems = [];
    this.createOrderItemByPlan(plan)

    setTimeout(() => {
      this.nextStep();
    }, 10);
  }

  nextStep() {
    this.orderStepper?.next();
  }
  get f() {
    return this.orderForms.controls;
  }
 
  saveCompany() {
    if (this.company) {
      this.company.vatnum = this.company.vatnum ? this.company.vatnum : null;
      this.companyRegLoading = true;
      this.companyService.createCompany(this.company).subscribe({
        next: (data: RequestResponse) => {

          if (data.data.company) this.userService.setActiveCompany(data.data.company, false);
          let companyCreatedMessage = $localize`Firma byla úspěšně vytvořena`;
          this.openSnackBar(companyCreatedMessage);
          this.companyRegLoading = false;
          this.newOrder.companyISUID =  this.userService.activeCompanyISUID;
          this.newOrder.orderItems.forEach((item) => {item.license.companyISUID = this.userService.activeCompanyISUID});
          this.orderForms.get('isCompanyValid')?.setValue(true, { emitEvent: true });

          this.nextStep();
        },
        error: (error) => {
          console.log(error);
          if (error.error.code == 409) { // for duplicated company
            this.companyError(error.error.code);
            this.validateCompanyStep("INVALID");
          }

          if (error.error.code == 400) { // for duplicated company
            this.companyError(error.error.code);
            this.validateCompanyStep("INVALID");
          }
         
          this.companyRegError = true;
          this.companyRegLoading = false;
        }
      })
    }
  }
  resetCompany() {
    this.company = null;
    this.companyRegError = false;
  }

  previousStep() {
    this.orderStepper?.previous();
  }
  
  addToCart() {

    this.cartService.addToCart(this.newOrder);
    this.dialog.open(OrderAddedComponent, {
      disableClose: true,
      width: '500px',
      maxHeight: '100vh',
      maxWidth: '500px',
      panelClass: 'order-added__addeddialog',
    });
    this.dialogRef.close();
  }
  updateDataModel(value: any) {
    if (value.orderType) { // pokud se mění order type

      if (this.orderType != value.orderType as OrderType) { // trigger if order type was changed to clear data
        this.orderType = value.orderType as OrderType;
        if (this.orderType == OrderType.NEW && this.plan) {
          this.newOrder.orderItems = [];
          this.createOrderItemByPlan(this.plan);
        }
        if (this.orderType == OrderType.EXTEND) {
          this.newOrder.orderItems = [];
        }
      }
    }


    this.newOrder = Object.assign(this.newOrder, value);
  }
  onCompanyUpdate(company: Company) {
    this.company = company;
  }
  onOrdersItemUpdate(orderItem: OrderItem) {
    this.newOrder.orderItems[this.newOrder.orderItems.length-1] = orderItem;
    this.newOrder = { ...this.newOrder }; // trigger changes
  }
  onOrdersItemsUpdate(orderItems: OrderItem[]) {
    this.newOrder.orderItems = orderItems;
    this.newOrder = { ...this.newOrder }; // trigger changes
  }

  onPriceChanged(orderPrice: OrderPrice) {

    this.newOrder.orderItems.forEach((item: OrderItem, index) => {
     
      var calculatedItem = orderPrice.orderItems.find(calculatedItem => calculatedItem._orderItemId == item._orderItemId);
      if(calculatedItem) {
        item.price = calculatedItem.price;
        item.priceSummary = calculatedItem.priceSummary;
        item.newLicense = calculatedItem.newLicense;
      }
    })
  }
  onPlanSelected(plan: PlanItem) {
    this.plan = plan;
  }
  validateProductStep(state: any) {
    this.orderForms.get('isProductValid')?.setValue(state, { emitEvent: false });
  }
  validateCompanyStep(state: any) {
    this.orderForms.get('isCompanyValid')?.setValue(state, { emitEvent: false });
  }
  closeStepper() {
    this.plan = null;
    this.dialogRef.close();
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'save__snack',
    });
  }
  companyError(code: number) {
    this.company = null;
    const dialogRef = this.dialog.open(CompanyErrorComponent, {
      disableClose: true,
      width: '400px',
      maxHeight: '100vh',
      maxWidth: '95vw',
      panelClass: 'company-error__dialog',
      data: {errorCode: code}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.validateCompanyStep("INVALID");
    });
  }
}
