<div class="licenses-list__contaner" *ngIf="!isLoadingResults; else loading">

  <div class="licenses-list licenses-list--accordion" *ngIf="template=='accordion'" cdkDropList >
    <mat-accordion *ngIf="licenses.length > 0; else noLicenses" multi >
      <mat-expansion-panel class=" mat-elevation-z0" hideToggle *ngFor="let license of groupedLicenses" [ngClass]="license.player ? 'has-player' : 'no-player'">
        <mat-expansion-panel-header  collapsedHeight="60px" class="licenses-list__header">
          <mat-panel-title>
            <div class="flex justify-start items-center" *ngIf="license.player">
              <div i18n-matTooltip  matTooltip="Blíží se konec licence" *ngIf="getRemaingDaysByEnd(license.endDate, license.startDate) < 14 && getRemaingDaysByEnd(license.endDate, license.startDate)!=0 " class="mr-5" >
                <mat-icon class="mat-color-yellow" aria-hidden="false" i18n-aria-label aria-label="Blíží se konec licence" fontIcon="warning"></mat-icon>
              </div>
              <div i18n-matTooltip  matTooltip="Vaše licence vypršela" *ngIf="getRemaingDaysByEnd(license.endDate, license.startDate) == 0" class="mr-5" >
                <mat-icon class="mat-color-red" aria-hidden="false" i18n-aria-label aria-label="Vaše licence vypršela" fontIcon="warning"></mat-icon>
              </div>
              <div  class="licenses-list__player-name">
                  {{ license.player.name }}
                
              </div>
            </div>
          </mat-panel-title>
          <mat-panel-description class="licenses-list__description">
            <div class="licenses-list__description__wrapper">
              
              <div class="licenses-list__info">
                <div >
                  {{license.licenseName}}
                </div>
                <div *ngIf="license.player">
                  {{ license.player.area}} m2
                </div>
                <span>
                  {{ license.startDate | date: 'dd. MM. yyyy' }} 
                </span>
                <span>
                  {{ license.endDate | date: 'dd. MM. yyyy' }} 
                </span>
                <app-state-tag [label]="ended" [color]="'red'" *ngIf="license.licenseState==licenseStateEnum.EXPIRED"></app-state-tag>
                <app-state-tag [label]="extended" [color]="'green'" *ngIf="license.licenseState==licenseStateEnum.EXTENDED"></app-state-tag>
                <app-state-tag [label]="active" [color]="'green'" *ngIf="license.licenseState==licenseStateEnum.ACTIVE"></app-state-tag>
                  <span i18n>Platnost:&nbsp;</span>
                  <div >{{ getRemaingDaysByEnd(license.endDate, license.startDate) | remainingDays}}</div>
                  <div *ngIf="!license.lastOrder" >
                    <button class="mat-mdc-raised-button--small" (click)="extendLicense(license, $event)" *ngIf="license.planId && !selectable && getRemaingDaysByEnd(license.endDate, license.startDate) < 14" mat-stroked-button color="primary" i18n-aria-label aria-label="Prodloužit licenci" i18n>Prodloužit</button>
                    <button class="mat-mdc-raised-button--small" (click)="changeLicense(license, $event)" *ngIf="!license.planId && !selectable && getRemaingDaysByEnd(license.endDate, license.startDate) < 14" mat-stroked-button color="primary" i18n-aria-label aria-label="Prodloužit licenci" i18n>Koupit</button>
                  </div>
                  <div *ngIf="license.lastOrder">
                    <button   class="mat-mdc-raised-button--small" *ngIf="!isPaid(license.lastOrder.orderState)" (click)="repeatPayment(license, $event)" mat-stroked-button color="primary" i18n-aria-label aria-label="Opakovat platbu" i18n>Opakovat platbu</button>
                  </div>
              </div>
            
              <div class="licenses-list__actions" *ngIf="license.player">
                <div>
                  <button i18n-matTooltip matTooltip="Otevřít detail přehrávače"  (click)="openPlayerDetail(license.player.playerId , $event)"   mat-icon-button i18n-aria-label aria-label="Otevře detail zařízení">
                    <mat-icon color="primary">video_settings</mat-icon>
                  </button>
    
                  <button i18n-matTooltip matTooltip="Poslouchat rádio"  (click)="playRadio(license.player.playerId , $event)"   mat-icon-button i18n-aria-label aria-label="Pustí rádio v novém okně">
                    <mat-icon color="primary">play_circle_filled</mat-icon>
                  </button>

                  <button *ngIf="license.licenseType != licenseTypeEnum.DEMO" i18n-matTooltip matTooltip="Stahnout certifikát licence"  (click)="downloadCertificate(license.player.playerId, $event, license.id)"   mat-icon-button i18n-aria-label aria-label="Stáhne certifikát licence">
                    <mat-icon color="primary">picture_as_pdf</mat-icon>
                  </button>
                  <!--button (click)="removePlayer(element)" *ngIf="element.player.category == playerCategoryEnum.TV"  mat-icon-button aria-label="Odebrat přehrávač z licence">
                    <mat-icon>delete</mat-icon>
                  </button-->
                </div>
                <button [matMenuTriggerFor]="licenceMenu"mat-icon-button i18n-aria-label aria-label="Více možností licence">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #licenceMenu="matMenu">
                  <button mat-menu-item (click)="cancelLicense()" i18n>Zrušit licenci</button>
                  <button mat-menu-item [disabled]="!license.isExtendable" [matTooltipPosition]="'before'" [matTooltipDisabled]="!license.extendableMessage"   [matTooltip]="license.extendableMessage!" *ngIf="license.planId" (click)="extendLicense(license)" i18n>Prodloužit</button>
                  <button  *ngIf="license.licenseType != licenseTypeEnum.DEMO" mat-menu-item (click)="downloadCertificate(license.player.playerId , $event, license.id)" i18n>Stáhnout certifikát v PDF</button>
                </mat-menu>
              </div>
            </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="licenses-list__previous-licenses"  *ngIf="license.previousLicenses; else noPreviousLicense">
            <h4>Historie licenci</h4>
            <div class="licenses-list__previous-license__item" *ngFor="let previouslicense of license.previousLicenses">
              <div class="licenses-list__previous-license__name licenses-list__previous-license__column" >
                {{ previouslicense.player?.name }}
              
              </div>
              <div class=" licenses-list__previous-license__info licenses-list__previous-license__column">
                <span>
                  {{ previouslicense.plan?.title }} 
                </span>
                <div *ngIf="license.player">
                  {{ license.player.area}} m2
                </div>
                <span>
                  {{ previouslicense.startDate | date: 'dd. MM. yyyy' }} 
                </span>
                <span>
                  {{ previouslicense.endDate | date: 'dd. MM. yyyy' }} 
                </span>
                <app-state-tag [label]="ended" [color]="'red'" *ngIf="previouslicense.licenseState==licenseStateEnum.EXPIRED"></app-state-tag>
                <app-state-tag [label]="extended" [color]="'green'" *ngIf="previouslicense.licenseState==licenseStateEnum.EXTENDED"></app-state-tag>
                <app-state-tag [label]="active" [color]="'green'" *ngIf="previouslicense.licenseState==licenseStateEnum.ACTIVE"></app-state-tag>
                <span i18n>Platnost:&nbsp;</span>
                {{ getRemaingDaysByEnd(previouslicense.endDate, previouslicense.startDate) | remainingDays }}
              </div>
              <div class=" licenses-list__previous-license__actions licenses-list__previous-license__column">
            
                <button  *ngIf="previouslicense.licenseType != licenseTypeEnum.DEMO"  i18n-matTooltip matTooltip="Stahnout certifikát licence"  (click)="downloadCertificate(previouslicense.player!.playerId, $event, previouslicense?.id)"   mat-icon-button i18n-aria-label aria-label="Stáhne certifikát licence">
                  <mat-icon color="primary">picture_as_pdf</mat-icon>
                </button>
              </div>
            </div>
          
          </div>
      </mat-expansion-panel>
    </mat-accordion>
  <div *ngIf="licenses.length <= 1 || (licenses.length <= 1 && licenses[0].licenseType == 'DEMO')">

    
    <app-pictogram [width]="180" (onClick)="openBenefitsDetail()" buttonText="Projít výhody" template="horizontal" [title]="pictogramSubscriptionTitle" [text]="pictogramSubscriptionText" icon="radio"></app-pictogram>


    <!--div class="pictogram pictogram--vcentered pictogram--opacity">
      <div>
        <svg id="Vrstva_2" data-name="Vrstva 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <defs>
            <style>
              .opacity-0 {
                opacity: 0;
              }
        
              .opacity-50 {
                opacity: .5;
              }
        
              .opacity-25 {
                opacity: .25;
              }
            </style>
          </defs>
          <g id="Vrstva_1-2" data-name="Vrstva 1">
            <g>
              <g>
                <path class="pictogram--primary" d="m376.32,215.48c-3.8-4.03-9.14-6.35-14.65-6.35h-150.11c-.37-2.41-1.1-4.7-2.14-6.81l128.22-47.86c1.92-.71,2.89-2.85,2.17-4.76-.71-1.92-2.85-2.89-4.76-2.17l-130.04,48.54c-4.38-4.5-10.5-7.31-17.26-7.31-12.02,0-22.01,8.85-23.8,20.38h-28.12c-11.35,0-20.24,8.89-20.24,20.24v148.83c0,11.35,8.89,20.24,20.24,20.24h225.32c11.35,0,20.24-8.89,20.24-20.24v-149.25c.26-4.95-1.54-9.73-5.07-13.48Zm-2.33,13.16v149.57c0,7.2-5.64,12.83-12.84,12.83h-225.32c-7.32,0-12.83-5.52-12.83-12.83v-148.83c0-7.32,5.52-12.83,12.83-12.83h225.83c3.48,0,6.86,1.46,9.26,4.02,2.15,2.28,3.23,5.15,3.06,8.08Zm-186.23-32.48c3.45,0,6.67,1.06,9.33,2.86,1.16.79,2.21,1.73,3.14,2.78.85.96,1.6,2,2.21,3.13.71,1.31,1.23,2.72,1.57,4.21h-32.5c.05-.21.11-.42.16-.63,1.91-7.1,8.39-12.35,16.09-12.35Z"/>
                <path class="pictogram--primary" d="m326.53,232.2c-16.29,0-29.54,13.25-29.54,29.54s13.25,29.54,29.54,29.54,29.54-13.25,29.54-29.54-13.25-29.54-29.54-29.54Zm0,51.68c-12.21,0-22.14-9.93-22.14-22.13s9.93-22.14,22.14-22.14,22.14,9.93,22.14,22.14-9.93,22.13-22.14,22.13Z"/>
                <path class="pictogram--primary" d="m201.44,240.86c-34.04,0-61.73,27.69-61.73,61.73s27.69,61.73,61.73,61.73,61.73-27.69,61.73-61.73-27.69-61.73-61.73-61.73Zm-54.32,61.73c0-29.95,24.37-54.32,54.32-54.32s54.32,24.37,54.32,54.32-24.37,54.32-54.32,54.32-54.32-24.37-54.32-54.32Z"/>
                <path class="pictogram--primary" d="m234.58,312.25c-1.66-1.33-4.31-1-5.64,1-5.97,8.96-16.59,14.6-27.55,14.6s-21.24-5.31-27.55-14.6c-1-1.66-3.65-2.32-5.64-1-1.66,1.33-2.32,3.65-1,5.64,7.63,11.62,20.58,18.59,34.52,18.59s26.55-6.97,33.85-18.59c1.33-1.66,1-4.31-1-5.64Z"/>
                <path class="pictogram--primary" d="m318.22,263.88c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                <path class="pictogram--primary" d="m217.95,303.14c2.04,0,3.7-1.66,3.7-3.7v-13.96c0-2.04-1.66-3.7-3.7-3.7s-3.7,1.66-3.7,3.7v13.96c0,2.04,1.66,3.7,3.7,3.7Z"/>
                <path class="pictogram--primary" d="m184.12,303.14c2.04,0,3.7-1.66,3.7-3.7v-13.96c0-2.04-1.66-3.7-3.7-3.7s-3.7,1.66-3.7,3.7v13.96c0,2.04,1.66,3.7,3.7,3.7Z"/>
                <polygon class="pictogram--primary" points="307.08 309.67 303.38 309.67 299.67 309.67 299.67 312.95 286.46 312.95 286.46 320.36 299.67 320.36 299.67 323.63 307.08 323.63 307.08 320.36 354.12 320.36 354.12 316.65 354.12 312.95 307.08 312.95 307.08 309.67"/>
                <polygon class="pictogram--primary" points="307.08 331.66 299.67 331.66 299.67 334.93 286.46 334.93 286.46 342.34 299.67 342.34 299.67 345.62 303.38 345.62 307.08 345.62 307.08 342.34 354.12 342.34 354.12 338.64 354.12 334.93 307.08 334.93 307.08 331.66"/>
                <polygon class="pictogram--primary" points="307.08 353.64 299.67 353.64 299.67 356.92 286.46 356.92 286.46 364.32 299.67 364.32 299.67 367.6 303.38 367.6 307.08 367.6 307.08 364.32 354.12 364.32 354.12 360.62 354.12 356.92 307.08 356.92 307.08 353.64"/>
              </g>
              <g class="opacity-25">
                <path class="pictogram--primary" d="m370.94,220.56c-2.41-2.56-5.79-4.02-9.26-4.02h-225.83c-7.32,0-12.83,5.52-12.83,12.83v148.83c0,7.32,5.52,12.83,12.83,12.83h225.32c7.2,0,12.83-5.64,12.83-12.83v-149.57c.18-2.93-.91-5.8-3.05-8.08Zm-84.47,92.39h13.21v-3.28h7.41v3.28h47.04v7.41h-47.04v3.28h-7.41v-3.28h-13.21v-7.41Zm0,21.98h13.21v-3.28h7.41v3.28h47.04v7.41h-47.04v3.28h-7.41v-3.28h-13.21v-7.41Zm-85.02,29.39c-34.04,0-61.73-27.69-61.73-61.73s27.69-61.73,61.73-61.73,61.73,27.69,61.73,61.73-27.69,61.73-61.73,61.73Zm152.68,0h-47.04v3.28h-7.41v-3.28h-13.21v-7.41h13.21v-3.28h7.41v3.28h47.04v7.41Zm-27.59-73.03c-16.29,0-29.54-13.25-29.54-29.54s13.25-29.54,29.54-29.54,29.54,13.25,29.54,29.54-13.25,29.54-29.54,29.54Z"/>
                <path class="pictogram--primary" d="m326.53,239.61c-12.21,0-22.14,9.93-22.14,22.14s9.93,22.13,22.14,22.13,22.14-9.93,22.14-22.13-9.93-22.14-22.14-22.14Zm-8.31,33.49c-2.55,0-4.61-2.06-4.61-4.61s2.06-4.61,4.61-4.61,4.6,2.06,4.6,4.61-2.06,4.61-4.6,4.61Z"/>
              </g>
              <g class="opacity-50">
                <path class="pictogram--primary" d="m300.69,182.38c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                <path class="pictogram--primary" d="m350.03,169.47c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                <path class="pictogram--primary" d="m313.61,114.86c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.61-2.06,4.61-4.61-2.06-4.61-4.61-4.61Z"/>
                <path class="pictogram--primary" d="m203.54,138.05c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                <path class="pictogram--primary" d="m151.86,127c2.55,0,4.6-2.06,4.6-4.61s-2.06-4.61-4.6-4.61-4.61,2.06-4.61,4.61,2.06,4.61,4.61,4.61Z"/>
                <path class="pictogram--primary" d="m126.02,188.35c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                <path class="pictogram--primary" d="m258.83,122.4c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                <path class="pictogram--primary" d="m154.26,164.21l-15.22-4.23,9.1-10.26c.87-.99,1.16-2.36.75-3.61-.41-1.25-1.46-2.19-2.74-2.47l-26.07-5.58c-2-.42-3.97.85-4.4,2.85s.85,3.97,2.85,4.4l19.87,4.25-8.82,9.95c-.85.96-1.14,2.28-.78,3.5.36,1.23,1.33,2.18,2.56,2.52l20.93,5.82c.33.09.67.14.99.14,1.62,0,3.11-1.07,3.57-2.71.55-1.97-.61-4.01-2.58-4.56Z"/>
                <path class="pictogram--primary" d="m383.47,161.33l9.39-11.76c1.21-1.51,1.05-3.7-.37-5.02l-9.52-8.86,11.85-12.58c1.4-1.49,1.33-3.83-.16-5.23-1.49-1.4-3.83-1.33-5.23.16l-14.41,15.29c-.68.72-1.04,1.67-1.01,2.66s.46,1.92,1.18,2.59l9.73,9.06-9.33,11.68c-1.18,1.48-1.06,3.62.29,4.95l12.63,12.45c.72.71,1.66,1.07,2.6,1.07s1.91-.37,2.64-1.1c1.44-1.46,1.42-3.8-.04-5.24l-10.26-10.11Z"/>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="pictogram__text">
        <h3 i18n>Poznejte výhody předplatného</h3>
        <p i18n>Vaše demo licence je časově omezená na 14 dní. Podívejte se na výhody, které získáte v případě předplacení našich služeb.</p>
      </div>
      <div class="pictogram__action">
        <button  mat-raised-button color="primary" class="mat-mdc-raised-button--shadow"  (click)="openBenefitsDetail()" i18n>Projít výhody</button>
      </div>
    </div-->


  </div>
</div>

<div *ngIf="template=='table'" class="licenses-list__table">
  <table mat-table [dataSource]="dataSource" *ngIf="licenses.length > 0; else noLicenses" >
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select" >
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox color="primary" (change)="$event ? toggleAllRows() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let element"  >
      <mat-checkbox [disabled]="!element.isExtendable" color="primary"  (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(element) : null"
                    [checked]="selection.isSelected(element)"
                    [aria-label]="checkboxLabel(element)">
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="licenseName">
    <th mat-header-cell *matHeaderCellDef i18n>Plán</th>
    <td mat-cell *matCellDef="let element"> {{element.licenseName}} </td>
  </ng-container>

  <!-- startDate  Column -->
  <ng-container matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef i18n>Začátek</th>
    <td mat-cell *matCellDef="let element"> {{element.startDate | date: 'dd. MM. yyyy' }}  </td>
  </ng-container>
  <!-- endDate  Column -->
  <ng-container matColumnDef="endDate">
    <th mat-header-cell *matHeaderCellDef i18n>Konec</th>
    <td mat-cell *matCellDef="let element"> {{element.endDate  | date: 'dd. MM. yyyy' }} </td>
  </ng-container>

  <!-- endDate  Column -->
  <ng-container matColumnDef="remainingDays">
    <th mat-header-cell *matHeaderCellDef  i18n>Platnost</th>
    <td mat-cell *matCellDef="let element" > 
      <div class="flex items-center gap-6">
        <div >{{ getRemaingDaysByEnd(element.endDate, element.startDate) | remainingDays}}</div>
        <div *ngIf="!element.lastOrder" >
          <button (click)="extendLicense(element)" *ngIf="element.planId && !selectable && getRemaingDaysByEnd(element.endDate, element.startDate) < 14" mat-raised-button color="primary" i18n-aria-label aria-label="Prodloužit licenci" i18n>Prodloužit</button>
          <button (click)="changeLicense(element)" *ngIf="!element.planId && !selectable && getRemaingDaysByEnd(element.endDate, element.startDate) < 14" mat-raised-button color="primary" i18n-aria-label aria-label="Prodloužit licenci" i18n>Koupit</button>
        </div>
        <div *ngIf="element.lastOrder">
          <button *ngIf="!isPaid(element.lastOrder.orderState)" (click)="repeatPayment(element)" mat-raised-button color="primary" i18n-aria-label aria-label="Opakovat platbu" i18n>Opakovat platbu</button>
        </div>
      
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="area">
    <th mat-header-cell *matHeaderCellDef i18n>Plocha</th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="element.player.area">
        {{ element.player.area}} m2
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="player">
        <th mat-header-cell *matHeaderCellDef i18n style="min-width: 20%;">Provozovna / přehrávač</th>
        <td mat-cell *matCellDef="let element" style="min-width: 20%;"  >
          
            <div class="licenses-list__draglist flex items-center" 
              *ngIf="!element.playerId" 
              cdkDropList 
              cdkDropListConnectedTo="devices"
              (cdkDropListDropped)="dropInLicense($event, element)" 
              [cdkDropListEnterPredicate]="dropListEnterPredicate(element)">
              
              <div class="licenses-list__dropzone" *ngIf="isDeviceDragged" ></div>
              <div  *ngIf="!isDeviceDragged" i18n>žádný přehrávač</div>
            </div>
            <div class="flex justify-start items-center" *ngIf="element.player">
              <div i18n-matTooltip  matTooltip="Blíží se konec licence" *ngIf="getRemaingDaysByEnd(element.endDate, element.startDate) < 14 && getRemaingDaysByEnd(element.endDate, element.startDate)!=0" class="mr-5" >
                <mat-icon class="mat-color-yellow" aria-hidden="false" i18n-aria-label aria-label="Blíží se konec licence" fontIcon="warning"></mat-icon>
              </div>
              <div i18n-matTooltip  matTooltip="Vaše licence vypršela" *ngIf="getRemaingDaysByEnd(element.endDate, element.startDate) == 0  " class="mr-5" >
                <mat-icon class="mat-color-red" aria-hidden="false" i18n-aria-label aria-label="Vaše licence vypršela" fontIcon="warning"></mat-icon>
              </div>
              <span>
                  {{ element.player.name }}
              </span>
            </div>
        </td>
      </ng-container>
    
      <ng-container matColumnDef="editors" >
        <th mat-header-cell *matHeaderCellDef style="text-align: right;" i18n></th>
        <td mat-cell *matCellDef="let element" style="text-align: right;"> 
          <div class="licenses-list__actions">
       
            <button i18n-matTooltip matTooltip="Otevřít detail přehrávače"  (click)="openPlayerDetail(element.playerId)"   mat-icon-button i18n-aria-label aria-label="Otevře detail zařízení">
              <mat-icon color="primary">video_settings</mat-icon>
            </button>

            <button i18n-matTooltip matTooltip="Poslouchat rádio"  (click)="playRadio(element.playerId)"   mat-icon-button i18n-aria-label aria-label="Pustí rádio v novém okně">
              <mat-icon color="primary">play_circle_filled</mat-icon>
            </button>

            <button i18n-matTooltip matTooltip="Stahnout certifikát licence"  (click)="downloadCertificate(element.player.playerId, $event, element.id)"   mat-icon-button i18n-aria-label aria-label="Stáhne certifikát licence">
              <mat-icon color="primary">license</mat-icon>
            </button>
    
            <button [matMenuTriggerFor]="licenceMenu"mat-icon-button i18n-aria-label aria-label="Více možností licence">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #licenceMenu="matMenu">
              <button mat-menu-item (click)="cancelLicense()" i18n>Zrušit licenci</button>
              <button mat-menu-item [disabled]="!element.isExtendable" [matTooltipPosition]="'before'" [matTooltipDisabled]="!element.extendableMessage"   [matTooltip]="element.extendableMessage" *ngIf="element.planId" (click)="extendLicense(element)" i18n>Prodloužit</button>
              <button mat-menu-item (click)="downloadCertificate(element.playerId, $event, element.id)" *ngIf="element.planId" i18n>Stáhnout certifikát v PDF</button>
            </mat-menu>
          </div>
        </td>
      </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" matTooltipPosition="left" [matTooltipDisabled]="!row.extendableMessage || !extendable"   [matTooltip]="row.extendableMessage"  [ngClass]="{'not-extendable': !row.isExtendable && selectable }">
  </tr>
  </table>
  <!--p class="mt-6" *ngIf="selectable" i18n>počet vybraných licencí: {{selection.selected.length}}</p-->
  </div>
</div>



<ng-template #noplayer>
</ng-template>

<ng-template #noLicenses>
  <p class="mt-5" i18n>Nemáte žádnou zaplacenou licenci.</p>
</ng-template>

<ng-template #noPreviousLicense>
  <p class="mt-5" i18n>Neevidujeme žádnou předchozí licenci</p>
</ng-template>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>

